<template>
  <div class="header">
    <div class="index-top">
      <img src="../assets/indexImage/logo.png" alt="" key="" @click="goIndex" />
      <div class="top-search">
        <input
          type=""
          placeholder="请输入文字"
          v-model="searchVal"
          @keydown.enter="goSearch"
        />
        <img src="../assets/indexImage/search.png" alt="" @click="goSearch" />
      </div>
      <div class="top-link">
        <div>
          <img src="../assets/indexImage/shouji.png" alt="" />
          <span>{{ Phone }}</span>
        </div>
        <div>
          <img src="../assets/indexImage/dianhua.png" alt="" />
          <span>{{ cz }}(含传真)</span>
        </div>
        <div>
          <img src="../assets/indexImage/emil.png" alt="" />
          <span>{{ emil }}</span>
        </div>
      </div>
    </div>
    <div class="index-navigation">
      <span
        v-for="(item, index) in navigation"
        :key="index"
        :class="currentIndex == index ? 'active' : ''"
        @click="changeItem(item.id)"
        >{{ item.name }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchVal: "",
      navigation: [],
      Phone: "",
      cz: "",
      emil: "",
    };
  },
  props: {
    currentIndex: {
      type: Number | String,
      default() {
        return 0;
      },
    },
  },
  created() {
    this.getItem();
    console.log(this.navigation, "this.navigation");
  },
  methods: {
    getItem() {
      this.$http({
        url: "/index/setList",
      }).then((res) => {
        this.navigation = res.data.menu;
        this.Phone = res.data.home_phone;
        this.cz = res.data.home_cz;
        this.emil = res.data.home_email;
        console.log(this.emil, "emil");
      });
    },
    changeItem(id) {
      if (id == 1) {
        this.$router.push("/index");
      } else if (id == 4) {
        this.$router.push("/figure");
      } else if (id == 5) {
        this.$router.push("/dynamic");
      } else if (id == 6) {
        this.$router.push("/video");
      } else if (id == 7) {
        this.$router.push("/rural");
      } else if (id == 8) {
        this.$router.push("/healthylife");
      } else if (id == 9) {
        this.$router.push("/complaints");
      } else if (id == 3) {
        this.$router.push("/hot");
      }
      this.$forceUpdate();
    },
    goSearch() {
      if (this.searchVal) {
        this.$router.push({
          path: "/search",
          query: {
            searchVal: this.searchVal,
          },
        });
      } else {
        this.$message({
          message: "请输入要搜索的内容",
          type: "warning",
        });
      }
    },
    goIndex() {
      this.$router.push("/index");
    },
  },
};
</script>

<style lang="less" scoped>
.active {
  background-color: #12407d !important;
}
.header {
  width: 100%;
  .index-top {
    display: flex;
    align-items: center;
    width: 1200px;
    height: 121px;
    padding: 23px 0 23px 0;
    margin: 0 auto;
    > img:nth-child(1) {
      width: 309px;
      height: 90px;
      margin-right: 56px;
      cursor: pointer;
    }
    .top-search {
      display: flex;
      align-items: center;
      width: 350px;
      height: 56px;
      margin-right: 32px;
      border: 1px solid #bdd4ff;
      > input:nth-child(1) {
        border: none;
        outline: none;
        width: 300px;
        height: 56px;
        text-indent: 1.5em;
      }
      > img:nth-child(2) {
        width: 28px;
        height: 28px;
      }
    }
    .top-link {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 121px;
      color: #2050ac;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        margin: 10px 0 15px 29px;
        font-size: 21px;
        white-space: nowrap;
        > img:nth-child(1) {
          width: 23px;
          height: 31px;
          margin-right: 25px;
        }
      }
      > div:nth-child(2) {
        display: flex;
        align-items: center;
        margin: 0px 0 15px 29px;
        font-size: 21px;
        white-space: nowrap;
        > img:nth-child(1) {
          width: 32px;
          height: 32px;
          margin-right: 21px;
        }
      }
      > div:nth-child(3) {
        display: flex;
        align-items: center;
        margin: 0px 0 10px 29px;
        font-size: 21px;
        white-space: nowrap;
        > img:nth-child(1) {
          width: 31px;
          height: 28px;
          margin-right: 21px;
        }
      }
    }
  }
  .index-navigation {
    width: 1200px;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 52px;
    background-color: #2050ac;
    color: #ffffff;
    span {
      height: 58px;
      display: block;
      flex: 1;
      font-size: 18px;
      text-align: center;
      line-height: 58px;
      font-family: "Microsoft Yahei";
      cursor: pointer;
    }
  }
}
</style>
