<template>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-top">
        <img src="../assets/componentsImage/FooterImg/logo.png" alt="" />
        <div>
          <span
            v-for="(item, index) in itemObj.downMenu"
            :key="index"
            @click="goPath(index)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="footer_color">
        <div class="footer-link">
          <span>友情链接：</span>
          <div>
            <span
              v-for="(item, index) in itemObj.links"
              :key="index"
              @click="goLink(item.linkurl)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="footer-phone">
          <div>
            <div>
              <span>热线: {{ itemObj.home_cz }}</span>
              <span>手机: {{ itemObj.home_phone }}</span>
              <span>传真: {{ itemObj.home_cz }}</span>
            </div>
            <div>
              <span>邮箱: {{ itemObj.home_email }}</span>
              <span>地址: {{ itemObj.home_address }}</span>
            </div>
          </div>
          <div class="footer-code">
            <img :src="baseUrl + itemObj.home_fotewmont" alt="" />
            <span>资本中原</span>
          </div>
          <div class="footer-code">
            <img :src="baseUrl + itemObj.home_fotewmtwo" alt="" />
            <span>河南省资本圈</span>
          </div>
        </div>
      </div>
      <div class="footer-dec">
        <div>
          <a href="https://beian.miit.gov.cn" target="_blank"
            >备案信息: {{ itemObj.beian }}</a
          >
          <span>豫公网安备 {{ itemObj.home_gongan }}</span>
        </div>
        <span v-if="itemObj.copyRightNotice"
          >版权说明: {{ itemObj.copyRightNotice }}
        </span>
        <span v-if="itemObj.transferNotice"
          >转载说明: {{ itemObj.transferNotice }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      itemObj: {},
      baseUrl: "",
    };
  },
  created() {
    this.baseUrl = this.BaseUrl;
    this.getItem();
  },
  methods: {
    getItem() {
      this.$http({
        url: "/index/setList",
      }).then((res) => {
        this.itemObj = res.data;
        console.log(res.data);
      });
    },
    goPath(index) {
      if (index == 0) {
        this.$router.push("/index");
      } else if (index == 1) {
        this.$router.push("/about");
      } else if (index == 2) {
        this.$router.push("/hot");
      } else if (index == 3) {
        this.$router.push("/figure");
      } else if (index == 4) {
        this.$router.push("/dynamic");
      } else if (index == 5) {
        this.$router.push("/video");
      } else if (index == 6) {
        this.$router.push("/rural");
      } else if (index == 7) {
        this.$router.push("/healthylife");
      } else if (index == 8) {
        this.$router.push("/complaints");
      }
    },
    goLink(i) {
      window.location.href = i;
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  // width: 1903px;
  bottom: 0;
  width: 100%;
  // margin-top: 150px;
  margin: 83px auto 0;
  background-color: #12407d !important;
  overflow: hidden;
  box-sizing: border-box;
  .footer-content {
    width: 1200px;
    margin: 20px auto 0;
    padding-bottom: 20px;
    box-sizing: border-box;
    .footer-top {
      display: flex;
      align-items: center;
      margin-bottom: 19px;
      > img:nth-child(1) {
        width: 185px;
        height: 53px;
        margin-right: 77px;
      }
      > div:nth-child(2) {
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: #fff;
        > span {
          flex: 1;
          padding: 0 6px;
          text-align: center;
          border-right: 1px solid #fff;
          cursor: pointer;
        }
        > span:last-child {
          border-right: none;
        }
      }
    }
    .footer_color {
      width: 100%;
      background-color: #0a2d72;
      padding: 8px 26px;
      margin: 0px;
      border-radius: 20px;
      box-sizing: border-box;
      .footer-link {
        display: flex;
        color: #fff;
        padding-top: 10px;
        font-size: 20px;
        margin-bottom: 20px;
        > span:nth-child(1) {
          margin-right: 25px;
        }
        > div:nth-child(2) {
          flex: 1;
          font-size: 20px;
          color: #fff;
          > span {
            flex: 1;
            white-space: nowrap;
            margin: 0px 20px;
            cursor: pointer;
          }
        }
      }
      .footer-item {
        font-size: 20px;
        margin-bottom: 64px;
        color: #fff;
        > span {
          margin-right: 20px;
        }
      }
      .footer-phone {
        display: flex;
        color: #fff;
        //   background-color: #0a2d5c;
        > div {
          display: flex;
          flex-direction: column;
          width: 930px;
          > div:nth-child(1) {
            width: 930px;
            font-size: 18px;
            margin-bottom: 18px;
            margin-right: 20px;
            > span {
              margin-right: 60px;
            }
          }
          > div:nth-child(2) {
            font-size: 18px;
            margin-bottom: 11px;
            white-space: nowrap;
            > span {
              margin-right: 50px;
            }
          }
        }
        > div:nth-child(1) {
          // margin-right: 80px;
        }
        > div:nth-child(2) {
          margin-right: 20px;
        }
        .footer-code {
          width: 100px;
          height: 130px;
          display: block;
          align-items: center;
          flex-direction: row;
          margin-right: 20px;
          margin-top: -40px;
          // margin-left: 20px;
          > img {
            width: 100px;
            height: 100px;
          }
          > span {
            display: block;
            text-align: center;
            white-space: nowrap;
          }
        }
      }
    }
    .footer-dec {
      display: flex;
      flex-direction: column;
      width: 1200px;
      text-align: center;
      font-size: 13px;
      color: #fff;
      margin-top: 10px;
      font-family: "宋体";
      > div:nth-child(1) {
        > a:nth-child(1) {
          text-decoration: none;
          color: #fff;
          margin-right: 10px;
        }
      }
      > span:nth-child(2) {
        margin: 5px 0;
      }
    }
  }
}
</style>
